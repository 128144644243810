import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { Customer } from '@/models'
import { RootState } from '@/store/types'

const getDefaultState = (): CustomersState => {
  return {
    customerList: [],
    customerListIsLoading: false,
    addCustomerIsLoading: false,
    addCustomerError: false,
    addCustomerErrorMessage: '',
    newCustomerId: 0,
    updateCustomerIsLoading: false,
    updateCustomerError: false,
    updateCustomerErrorMessage: '',
    updatePrimaryContactErrorMessage: null,
    customerStorageData: {},
    customerStorageIsLoading: false,
  }
}

const state = getDefaultState()

const getters: GetterTree<CustomersState, RootState> = {
  currentCustomer: (state) => (customerId: number) => {
    return state.customerList.find((customer: Customer) => {
      if (customer.customerId === customerId) {
        return customer
      }
    })
  },
  customers: (state) => {
    return state.customerList
  },
  customerList: (state) => {
    const list = state.customerList.map((item) => {
      if (item.address.includes('|')) {
        const updatedString = item.address.replaceAll('|', ' ')
        return { ...item, address: updatedString }
      }
      return item
    })
    return list
  },
}

const mutations: MutationTree<CustomersState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setCustomerList(state, value) {
    state.customerList = value
  },
  setCustomerListIsLoading(state, value) {
    state.customerListIsLoading = value
  },
  setAddCustomerIsLoading(state, value) {
    state.addCustomerIsLoading = value
  },
  setAddCustomerError(state, value) {
    state.addCustomerError = value
  },
  setAddCustomerErrorMessage(state, value) {
    state.addCustomerErrorMessage = value
  },
  setNewCustomerId(state, value) {
    state.newCustomerId = value
  },
  setUpdateCustomerIsLoading(state, value) {
    state.updateCustomerIsLoading = value
  },
  setUpdateCustomerError(state, value) {
    state.updateCustomerError = value
  },
  setUpdateCustomerErrorMessage(state, value) {
    state.updateCustomerErrorMessage = value
  },
  setUpdatePrimaryContactErrorMessage(state, value) {
    state.updatePrimaryContactErrorMessage = value
  },
  setUpdatedCustomer(state, value) {
    state.customerList = state.customerList.map((item) => {
      return item.customerId === value.customerId ? value : item
    })
  },
  setUpdatedPrimaryContact(state, value) {
    state.customerList = state.customerList.map((item) => {
      return item.customerId === value.customerId ? value : item
    })
  },
  setCustomerStorage(state, value) {
    state.customerStorageData = value
  },
  setCustomerStorageIsLoading(state, value) {
    state.customerStorageIsLoading = value
  },
}

const actions: ActionTree<CustomersState, RootState> = {
  async getCustomerList({ commit }, payload) {
    commit('setCustomerListIsLoading', true)
    return repository.Customer.GetCustomers(payload)
      .then((res) => {
        commit('setCustomerList', res.data.items)
      })
      .catch(() => {
        //error message here
      })
      .finally(() => {
        commit('setCustomerListIsLoading', false)
      })
  },
  async getCustomerListNoLoading({ commit }, payload) {
    return repository.Customer.GetCustomers(payload).then((res) => {
      commit('setCustomerList', res.data.items)
    })
  },
  async getCustomerStorageCapacity({ commit }, payload) {
    commit('setCustomerStorageIsLoading', true)
    return repository.Customer.GetStorageUsage(payload)
      .then((res) => {
        commit('setCustomerStorage', res.data)
      })
      .catch(() => {})
      .finally(() => {
        commit('setCustomerStorageIsLoading', false)
      })
  },
  async addCustomer({ commit }, payload) {
    commit('setAddCustomerIsLoading', true)
    return repository.Customer.Add(payload)
      .then((res) => {
        commit('setNewCustomerId', res.data.customerId)
        commit('setAddCustomerError', false)
      })
      .catch((err) => {
        commit('setAddCustomerError', true)
        commit(
          'setAddCustomerErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setAddCustomerIsLoading', false)
      })
  },
  async updateCustomer({ commit }, payload) {
    commit('setUpdateCustomerIsLoading', true)
    return repository.Customer.Update(payload)
      .then((res) => {
        commit('setUpdatedCustomer', res.data)
        commit('setUpdateCustomerError', false)
      })
      .catch((err) => {
        commit('setUpdateCustomerError', true)
        commit(
          'setUpdateCustomerErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdateCustomerIsLoading', false)
      })
  },
  async updatePrimaryUserContact({ commit }, payload) {
    return repository.Customer.UpdatePrimaryUserContact(payload)
      .then((res) => {
        commit('setUpdatedPrimaryContact', res.data)
        commit('setUpdatePrimaryContactErrorMessage', null)
      })
      .catch((err) => {
        commit(
          'setUpdatePrimaryContactErrorMessage',
          err.response.data.messageLocalizable
        )
      })
      .finally(() => {})
  },
}

const Customers: Module<CustomersState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default Customers

interface CustomersState {
  customerList: Customer[]
  customerListIsLoading: boolean
  addCustomerIsLoading: boolean
  addCustomerError: boolean
  addCustomerErrorMessage: string
  newCustomerId: number
  updateCustomerIsLoading: boolean
  updateCustomerError: boolean
  updateCustomerErrorMessage: string
  updatePrimaryContactErrorMessage: string | null
  customerStorageData: any
  customerStorageIsLoading: boolean
}
