import repository from '@/repository'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): EnvironmentState => {
  return {
    version: 'NOT_SET',
    needsUpdate: false,
    features: {},
    settings: {},
  }
}

const state = getDefaultState()

const getters: GetterTree<EnvironmentState, RootState> = {
  hasStorageLimit: (state) => state.features.StorageLimit || false,
  hasChargify: (state) => state.features.Chargify || false,
  hasAccessTokens: (state) => state.features.AccessTokens || false,
  hasDewarping: (state) => state.features.Dewarping || false,
  hasAdvancedAnalytics: (state) => state.features.AdvancedAnalytics || false,
  hasAssistant: (state) => state.features.Assistant || false,
  hasMultiView: (state) => state.features.MultiCameraView || false,
  hasShareCase: (state) => state.features.ExternalCaseShare || false,
  hasAudioFeature: (state) => state.features.CameraAudio || false,
  hasStandardFpsGop: (state) => state.features.StandardFpsGop || false,
  hasDevicePermission: (state) => state.features.DevicePermission || false,
  hasFlexPackage: (state) => state.features.FlexPackage || false,
  hasVCAPresenceMonitoring: (state) =>
    state.features.VCAPresenceMonitoring || false,
  hasUpdateNVRCameraRetention: (state) =>
    state.features.UpdateNVRCameraRetention || false,
  canRemoveLocationFeature: (state) =>
    state.features['Location/CanRemove'] || false,
  canRemoveCustomerFeature: (state) =>
    state.features['Customer/CanRemove'] || false,
  canRemoveDealerFeature: (state) =>
    state.features['Dealer/CanRemove'] || false,
  chargifySettings: (state) => ({
    endpoint: state.settings['Chargify/Endpoint'] || '',
    publicKey: state.settings['Chargify/PublicKey'] || '',
  }),
  assistantSettings: (state) => ({
    endpoint: state.settings['AssistantEndpoint'] || '',
    userPrefix: state.settings['AssistantUserPrefix'] || '',
  }),
  bandwidthTestDataUrl: (state) => state.settings.BandwidthTestDataUrl || '',
  hasAlerts: (state) =>
    state.features['AdvanceAnalytics/AlertDefinition'] || false,
  hasRuleCategory: (state) =>
    state.features['AdvanceAnalytics/RuleCategory'] || false,
  hasNvrVCA: (state) => state.features['AdvanceAnalytics/NVRVCA'] || false,
  hasI18n: (state) => state.features.I18n || false,
}

const mutations: MutationTree<EnvironmentState> = {
  setVersion(state, value) {
    if (state.version !== value) {
      state.needsUpdate = state.version !== getDefaultState().version
      state.version = value
      console.log(`Version: ${value}`)
    }
  },
  setFeatures(state, value) {
    state.features = value || {}
  },
  setSettings(state, value) {
    state.settings = value || {}
  },
  reset(state) {
    Object.assign(state || {}, getDefaultState())
  },
}

const actions: ActionTree<EnvironmentState, RootState> = {
  async refreshFeatures({ commit }) {
    return repository.Status.GetAPIStatus().then(({ data }) => {
      commit('setVersion', data.sourceVersionUI)
      commit('setFeatures', data.features || {})
      console.log(data.description)
      for (const [key, value] of Object.entries(data.features)) {
        console.log(`${key}: ${value}`)
      }
    })
  },
  async refreshSettings({ commit }) {
    return repository.Environment.GetSettings().then((response) => {
      commit('setSettings', response.data)
    })
  },
}

const Environment: Module<EnvironmentState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default Environment

interface EnvironmentState {
  version: string
  needsUpdate: boolean
  features: Partial<Features>
  settings: Partial<Settings>
}

interface Features {
  'Location/CanRemove': boolean
  Chargify: boolean
  AccessTokens: boolean
  Dewarping: boolean
  AdvancedAnalytics: boolean
  Assistant: boolean
  MultiCameraView: boolean
  ExternalCaseShare: boolean
  CameraAudio: boolean
  StorageLimit: boolean
  StandardFpsGop: boolean
  UpdateNVRCameraRetention: boolean
  DevicePermission: boolean
  FlexPackage: boolean
  VCAPresenceMonitoring: boolean
  I18n: boolean
}

interface Settings {
  'Chargify/Endpoint': string
  'Chargify/PublicKey': string
  BandwidthTestDataUrl: string
}
